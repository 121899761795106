import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userStore from './user-store'
import shiftStore from './shift-store'
import showStore from './show-store'
import jobTitleStore from './job-title-store'
import clockTitleStore from './clock-title-store'
import rolesStore from './roles-store'
import careTeamStore from './care-team-store'
import diabetesComplicationsStore from './diabetes-complications-store'
import diabetesMedicineStore from './diabetes-medicine-store'
import diabetesMedicineBrandStore from './diabetes-medicine-brand-store'
import diabetesTypeStore from './diabetes-type-store'
import glucoseMonitorStore from './glucose-monitor-store'
import hospitalisationListStore from './hospitalisation-list-store'
import insulinStore from './insulin-store'
import insulinPumpStore from './insulin-pump-store'
import libreReasonStore from './libre-reason-store'
import pharmacyStore from './pharmacy-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    userStore,
    shiftStore,
    showStore,
    jobTitleStore,
    clockTitleStore,
    rolesStore,
    careTeamStore,
    diabetesComplicationsStore,
    diabetesMedicineBrandStore,
    diabetesMedicineStore,
    diabetesTypeStore,
    glucoseMonitorStore,
    insulinStore,
    insulinPumpStore,
    hospitalisationListStore,
    libreReasonStore,
    pharmacyStore,
  },
  strict: process.env.DEV,
})
