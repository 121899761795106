import apiConfig from '@/api/apiConfig'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(apiConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * We are checking role just for ease
 * @param {number} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole !== null) return { name: 'dashboard' }
  // return { name: 'auth-login' }
  if (userRole === null) return { name: 'auth-login' }

  if (userRole !== null && userRole === 4) {
    return { name: 'cde-patients-list' }
  }
  if (userRole !== null && userRole === 3) {
    return { name: 'dashboard-pharmacy' }
  }
  return { name: 'dashboard' }
}

// this just returns string
export const getDefaultHomeRoute = userRole => {
  // if (userRole !== null) return { name: 'dashboard' }
  // return { name: 'auth-login' }
  if (userRole === null) return 'auth-login'

  if (userRole !== null && userRole === 4) {
    return 'cde-patients-list'
  }
  if (userRole !== null && userRole === 3) {
    return 'dashboard-pharmacy'
  }
  return 'dashboard'
}

// if (userRole === null) return { name: 'auth-login' }

// if (userRole !== null && userRole === 4) {
//   return { name: 'cde-patients-list' }
// }
// return { name: 'dashboard' }
