export default {
  ADD_SHOW(state, item) {
    state.shows.unshift(item)
  },
  SET_SHOWS(state, shows) {
    state.shows = shows
  },
  UPDATE_SHOW(state, category) {
    const productIndex = state.shows.findIndex(p => p.id === category.id)
    Object.assign(state.shows[productIndex], category)
  },
  REMOVE_SHOW(state, itemId) {
    const ItemIndex = state.shows.findIndex(p => p.id === itemId)
    state.shows.splice(ItemIndex, 1)
  },
  SET_SHOW_LABELS(state, shifts) {
    state.showLabels = shifts
  },
}
