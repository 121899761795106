export default {
  SET_USERS(state, users) {
    state.users = users
  },
  REMOVE_RECORD(state, userId) {
    const userIndex = state.users.findIndex(u => u.id === userId)
    state.users.splice(userIndex, 1)
  },
  SET_USER_LABELS(state, users) {
    state.userLabels = users
  },
  SET_PHARMACISTS(state, pharmacists) {
    state.pharmacists = pharmacists
  },
  REMOVE_PHARMACIST_RECORD(state, userId) {
    const userIndex = state.pharmacists.findIndex(u => u.id === userId)
    state.pharmacists.splice(userIndex, 1)
  },
  SET_PHARMACIST_LABELS(state, pharmacists) {
    state.pharmacistLabels = pharmacists
  },
  SET_CDE_HCPS(state, cdeHcps) {
    state.cdeHcps = cdeHcps
  },
  REMOVE_CDE_HCP_RECORD(state, userId) {
    const userIndex = state.cdeHcps.findIndex(u => u.id === userId)
    state.cdeHcps.splice(userIndex, 1)
  },
  SET_CDE_HCP_LABELS(state, users) {
    state.cdeHcpLabels = users
  },
  SET_PATIENTS(state, patients) {
    state.patients = patients
  },
  REMOVE_PATIENT_RECORD(state, userId) {
    const userIndex = state.patients.findIndex(u => u.id === userId)
    state.patients.splice(userIndex, 1)
  },
  SET_PATIENT_LABELS(state, patients) {
    state.patientLabels = patients
  },
  SET_ADMINS(state, admins) {
    state.admins = admins
  },
  REMOVE_ADMIN_RECORD(state, userId) {
    const userIndex = state.admins.findIndex(u => u.id === userId)
    state.admins.splice(userIndex, 1)
  },
  SET_ADMIN_LABELS(state, admins) {
    state.adminLabels = admins
  },
}
