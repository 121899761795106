export default {
  ADD_LIBRE_REASON(state, item) {
    state.libreReasons.unshift(item)
  },
  SET_LIBRE_REASONS(state, libreReasons) {
    state.libreReasons = libreReasons
  },
  UPDATE_LIBRE_REASON(state, category) {
    const productIndex = state.libreReasons.findIndex(p => p.id === category.id)
    Object.assign(state.libreReasons[productIndex], category)
  },
  REMOVE_LIBRE_REASON(state, itemId) {
    const ItemIndex = state.libreReasons.findIndex(p => p.id === itemId)
    state.libreReasons.splice(ItemIndex, 1)
  },
  SET_LIBRE_REASON_LABELS(state, libreReasons) {
    state.libreReasonLabels = libreReasons
  },
}
