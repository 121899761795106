export default {
  ADD_JOB_TITLE(state, item) {
    state.jobTitles.unshift(item)
  },
  SET_JOB_TITLES(state, jobTitles) {
    state.jobTitles = jobTitles
  },
  UPDATE_JOB_TITLE(state, category) {
    const productIndex = state.jobTitles.findIndex(p => p.id === category.id)
    Object.assign(state.jobTitles[productIndex], category)
  },
  REMOVE_JOB_TITLE(state, itemId) {
    const ItemIndex = state.jobTitles.findIndex(p => p.id === itemId)
    state.jobTitles.splice(ItemIndex, 1)
  },
  SET_JOB_TITLE_LABELS(state, jobTitles) {
    state.jobTitleLabels = jobTitles
  },
}
