/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addInsulinPump({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/insulin-pump`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulinPumps({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/insulin-pump`)
        .then(response => {
          commit('SET_INSULIN_PUMPS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulinPumpLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/insulin_pump_labels/`)
        .then(response => {
          commit('SET_INSULIN_PUMP_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulinPump(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/insulin-pump/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateInsulinPump({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/insulin-pump/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeInsulinPump({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/insulin-pump/${pubId}`)
        .then(response => {
          commit('REMOVE_INSULIN_PUMP', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
