/* eslint-disable import/extensions */
// eslint-disable-next-line import/extensions
import state from './clockTitleStoreState.js'
import mutations from './clockTitleStoreMutations.js'
import actions from './clockTitleStoreActions.js'
import getters from './clockTitleStoreGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
