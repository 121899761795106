import state from './shiftStoreState'
import mutations from './shiftStoreMutations'
import actions from './shiftStoreActions'
import getters from './shiftStoreGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
