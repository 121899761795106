import state from './jobTitleStoreState'
import mutations from './jobTitleStoreMutations'
import actions from './jobTitleStoreActions'
import getters from './jobTitleStoreGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
