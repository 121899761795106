export default {
  ADD_CARE_TEAM(state, item) {
    state.careTeams.unshift(item)
  },
  SET_CARE_TEAMS(state, careTeams) {
    state.careTeams = careTeams
  },
  UPDATE_CARE_TEAM(state, category) {
    const productIndex = state.careTeams.findIndex(p => p.id === category.id)
    Object.assign(state.careTeams[productIndex], category)
  },
  REMOVE_CARE_TEAM(state, itemId) {
    const ItemIndex = state.careTeams.findIndex(p => p.id === itemId)
    state.careTeams.splice(ItemIndex, 1)
  },
  SET_CARE_TEAM_LABELS(state, careTeams) {
    state.careTeamLabels = careTeams
  },
}
