/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/users`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/users`)
        .then(response => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchUser(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/profile/users/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/users/${payload.pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeUser({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/users/${userId}`)
        .then(response => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchUserLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/auth/user_labels`)
        .then(response => {
          commit('SET_USER_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/profile/auth/updatepass`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateUserSocial({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/profile/update_social`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/auth/reset_password`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateOwnProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/profile/update_own_profile/${payload.pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Admins
  fetchAdminUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/admin_users`)
        .then(response => {
          commit('SET_ADMINS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Pharmacist
  addPharmacist({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/users`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // list
  fetchPharmacists({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/pharmacists`)
        .then(response => {
          commit('SET_PHARMACISTS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPharmacist(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/profile/users/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updatePharmacist({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/users/${payload.pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removePharmacist({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/users/${userId}`)
        .then(response => {
          commit('REMOVE_PHARMACIST_RECORD', userId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPharmacistLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/pharmacist_labels`)
        .then(response => {
          commit('SET_PHARMACIST_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Patients
  addPatient({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/users`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // list
  fetchPatients({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/patients`)
        .then(response => {
          commit('SET_PATIENTS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCdePatients({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/cde_patients`)
        .then(response => {
          commit('SET_PATIENTS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPatient(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/profile/users/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updatePatient({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/users/${payload.pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removePatient({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/users/${userId}`)
        .then(response => {
          commit('REMOVE_PATIENT_RECORD', userId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPatientLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/patient_labels`)
        .then(response => {
          commit('SET_PATIENT_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // HCP/CDE
  addCdeHcp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/users`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // list
  fetchCdeHcps({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/cde_hcps`)
        .then(response => {
          commit('SET_CDE_HCPS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCdeHcp(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/profile/users/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateCdeHcp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/users/${payload.pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeCdeHcp({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/users/${userId}`)
        .then(response => {
          commit('REMOVE_CDE_HCP_RECORD', userId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCdeHcpLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/cde_hcp_labels`)
        .then(response => {
          commit('SET_CDE_HCP_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchVisits(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/patient_visit_data/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPatientInfo(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_patient/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPreAssignedMedicationList(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_patient_assigned_medications/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientMedicationData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_medication_data/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removePatientMedicationDataItem({ commit }, { medicationDataItemID }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/resources/patient_medication_data/${medicationDataItemID}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientGeneralData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_general_info/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  saveCDEPatientData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/cde_update_patient_detail/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientDiabetesTypeData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_diabetes_type_info/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientGlucoseLibreData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_glucose_libre_info/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPatientPublicDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/booking/${payload.userId}/${payload.pharmacyId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchPreAssignedInsulinList(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_patient_assigned_insulins/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientInsulinData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_insulin_data/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removePatientInsulinDataItem({ commit }, { insulinDataItemID }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/resources/patient_insulin_data/${insulinDataItemID}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientCareTeamData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_care_team_data/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientCareTeamDataByPharmacy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_care_team_data_by_pharmacy/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getPharmacyLibreLogins({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_pharmacy_libre_details/${payload.pid}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchPreAssignedService3MedicationList(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_patient_assigned_service3_medications/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchPreAssignedService3InsulinList(context, { userId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/get_patient_assigned_service3_insulins/${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientMedicationDataForService3({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_medication_data_service_3/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePatientInsulinDataForService3({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/resources/patient_insulin_data_service_3/${payload.user_pub_id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // check pharmacy auth for auto login
  checkPharmacyAuth({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/pharmacy_auth/login`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  uploadPatientReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/resources/patient_libre_report_upload/${payload.userPubId}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
