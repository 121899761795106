export default {
  ADD_ROLE(state, item) {
    state.roles.unshift(item)
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  UPDATE_ROLE(state, category) {
    const productIndex = state.roles.findIndex(p => p.id === category.id)
    Object.assign(state.roles[productIndex], category)
  },
  REMOVE_ROLE(state, itemId) {
    const ItemIndex = state.roles.findIndex(p => p.id === itemId)
    state.roles.splice(ItemIndex, 1)
  },
  SET_ROLE_LABELS(state, roles) {
    state.roleLabels = roles
  },
}
