/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addJobTitle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/job-title`, payload)
        .then(response => {
          // commit('ADD_JOB_TITLE', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchJobTitles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/job-title`)
        .then(response => {
          commit('SET_JOB_TITLES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchJobTitleLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/job-title-labels`)
        .then(response => {
          commit('SET_JOB_TITLE_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchJobTitle(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/job-title/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateJobTitle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/job-title/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeJobTitle({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/job-title/${pubId}`)
        .then(response => {
          commit('REMOVE_JOB_TITLE', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
