/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addLibreReason({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/libre-reason`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchLibreReasons({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/libre-reason`)
        .then(response => {
          commit('SET_LIBRE_REASONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchLibreReasonLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/libre_reason_labels/`)
        .then(response => {
          commit('SET_LIBRE_REASON_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchLibreReason(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/libre-reason/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateLibreReason({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/libre-reason/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeLibreReason({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/libre-reason/${pubId}`)
        .then(response => {
          commit('REMOVE_LIBRE_REASON', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchLibreStopReasonLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/libre_stop_reason_labels/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
