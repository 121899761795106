export default [
  {
    path: '/dashboard-pharmacy',
    name: 'dashboard-pharmacy',
    component: () => import('@/views/Home-Pharmacy.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },
  {
    path: '/pharmacy/patient/list',
    name: 'pharmacy-patients-list',
    component: () => import('@/views/apps/user/pharmacy-patient-list/UsersList.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },
  // Add new visit data
  {
    path: '/pharmacy/patient-visit/:id/:pid',
    name: 'pharmacy-patient-add-visit-data',
    component: () => import('@/views/apps/user/pharmacy-patient-data-view/PharmacyPatientLoginRedirect.vue'),
    // component: () => import('@/views/apps/user/pharmacy-patient-data-view/Patient-MainView.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  { path: '/pharmacy/auth-verified/:id/:pid', name: 'pharmacy-patient-auth-redirect', redirect: { name: 'pharmacy-patient-visit-authenticated' } },
  // this route is here pharmacy is authenticated via auto login
  {
    path: '/pharmacy/patient-visit-authenticated/:id/:pid',
    name: 'pharmacy-patient-visit-authenticated',
    component: () => import('@/views/apps/user/pharmacy-patient-data-view/Patient-MainViewV2.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },

  {
    path: '/pharmacy/patient-visit-1/:id/:pid',
    name: 'pharmacy-patient-visit-data',
    component: () => import('@/views/apps/user/pharmacy-patient-data-view/Patient-MainViewV2.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },
  {
    path: '/pharmacy/patient-referral-download/:id/:pid',
    name: 'pharmacy-patient-referral-download',
    component: () => import('@/views/apps/user/pharmacy-patient-data-view/PatientReferralReport.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },
]
