export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
  {
    path: '/my-profile/edit/:id',
    name: 'my-profile-edit',
    component: () => import('@/views/apps/my-profile/profile-edit/MyProfileEdit.vue'),
    meta: {
      resource: 'Pharmacist',
      action: 'read',
    },
  },
]
