export default {
  ADD_INSULIN(state, item) {
    state.insulins.unshift(item)
  },
  SET_INSULINS(state, insulins) {
    state.insulins = insulins
  },
  UPDATE_INSULIN(state, category) {
    const productIndex = state.insulins.findIndex(p => p.id === category.id)
    Object.assign(state.insulins[productIndex], category)
  },
  REMOVE_INSULIN(state, itemId) {
    const ItemIndex = state.insulins.findIndex(p => p.id === itemId)
    state.insulins.splice(ItemIndex, 1)
  },
  SET_INSULIN_LABELS(state, insulins) {
    state.insulinLabels = insulins
  },
}
