export default {
  ADD_DIABETES_MEDICINE(state, item) {
    state.diabetesMedicines.unshift(item)
  },
  SET_DIABETES_MEDICINES(state, diabetesMedicines) {
    state.diabetesMedicines = diabetesMedicines
  },
  UPDATE_DIABETES_MEDICINE(state, category) {
    const productIndex = state.diabetesMedicines.findIndex(p => p.id === category.id)
    Object.assign(state.diabetesMedicines[productIndex], category)
  },
  REMOVE_DIABETES_MEDICINE(state, itemId) {
    const ItemIndex = state.diabetesMedicines.findIndex(p => p.id === itemId)
    state.diabetesMedicines.splice(ItemIndex, 1)
  },
  SET_DIABETES_MEDICINE_LABELS(state, diabetesMedicines) {
    state.diabetesMedicineLabels = diabetesMedicines
  },
}
