export default {
  ADD_PHARMACY(state, item) {
    state.pharmacies.unshift(item)
  },
  SET_PHARMACYS(state, pharmacies) {
    state.pharmacies = pharmacies
  },
  UPDATE_PHARMACY(state, category) {
    const productIndex = state.pharmacies.findIndex(p => p.id === category.id)
    Object.assign(state.pharmacies[productIndex], category)
  },
  REMOVE_PHARMACY(state, itemId) {
    const ItemIndex = state.pharmacies.findIndex(p => p.id === itemId)
    state.pharmacies.splice(ItemIndex, 1)
  },
  SET_PHARMACY_LABELS(state, pharmacies) {
    state.pharmacyLabels = pharmacies
  },
}
