export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system/admin/list',
    name: 'system-admin-list',
    component: () => import('@/views/admin/users/admin/List.vue'),
  },
  {
    path: '/system/patient/list',
    name: 'system-patient-list',
    component: () => import('@/views/admin/users/patient/List.vue'),
  },
  {
    path: '/system/cde/list',
    name: 'system-cde-list',
    component: () => import('@/views/admin/users/cde/List.vue'),
  },
  {
    path: '/system/hcp/list',
    name: 'system-hcp-list',
    component: () => import('@/views/admin/users/hcp/List.vue'),
  },
  {
    path: '/system/pharmacist/list',
    name: 'system-pharmacist-list',
    component: () => import('@/views/admin/users/pharmacist/List.vue'),
  },
  {
    path: '/system/care-team/list',
    name: 'system-care-team-list',
    component: () => import('@/views/admin/care-team/list/List.vue'),
  },
  {
    path: '/system/care-team/edit/:id',
    name: 'system-care-team-edit',
    component: () => import('@/views/admin/care-team/edit/Edit.vue'),
  },

  {
    path: '/system/diabetes-complications/list',
    name: 'system-diabetes-complication-list',
    component: () => import('@/views/admin/diabetes-complications/list/List.vue'),
  },

  {
    path: '/system/diabetes-complication/edit/:id',
    name: 'system-diabetes-complication-edit',
    component: () => import('@/views/admin/diabetes-complications/edit/Edit.vue'),
  },

  {
    path: '/system/diabetes-medicine/list',
    name: 'system-diabetes-medicine-list',
    component: () => import('@/views/admin/diabetes-medicine/list/List.vue'),
  },

  {
    path: '/system/diabetes-medicine/edit/:id',
    name: 'system-diabetes-medicine-edit',
    component: () => import('@/views/admin/diabetes-medicine/edit/Edit.vue'),
  },

  {
    path: '/system/diabetes-medicine-brand/list',
    name: 'system-diabetes-medicine-brand-list',
    component: () => import('@/views/admin/diabetes-medicine-brand/list/List.vue'),
  },

  {
    path: '/system/diabetes-medicine-brand/edit/:id',
    name: 'system-diabetes-medicine-brand-edit',
    component: () => import('@/views/admin/diabetes-medicine-brand/edit/Edit.vue'),
  },

  {
    path: '/system/diabetes-type/list',
    name: 'system-diabetes-type-list',
    component: () => import('@/views/admin/diabetes-types/list/List.vue'),
  },

  {
    path: '/system/diabetes-medicine-type/edit/:id',
    name: 'system-diabetes-type-edit',
    component: () => import('@/views/admin/diabetes-types/edit/Edit.vue'),
  },

  {
    path: '/system/glucose-monitor/list',
    name: 'system-glucose-monitor-list',
    component: () => import('@/views/admin/glucose-monitor/list/List.vue'),
  },

  {
    path: '/system/glucose-monitor/edit/:id',
    name: 'system-glucose-monitor-edit',
    component: () => import('@/views/admin/glucose-monitor/edit/Edit.vue'),
  },

  {
    path: '/system/hospitalisation-list/list',
    name: 'system-hospitalisation-list',
    component: () => import('@/views/admin/hospitalisation-list/list/List.vue'),
  },

  {
    path: '/system/hospitalisation-list/edit/:id',
    name: 'system-hospitalisation-list-edit',
    component: () => import('@/views/admin/hospitalisation-list/edit/Edit.vue'),
  },

  {
    path: '/system/insulin/list',
    name: 'system-insulin-list',
    component: () => import('@/views/admin/insulin/list/List.vue'),
  },

  {
    path: '/system/insulin/edit/:id',
    name: 'system-insulin-edit',
    component: () => import('@/views/admin/insulin/edit/Edit.vue'),
  },

  {
    path: '/system/insulin-pump/list',
    name: 'system-insulin-pump-list',
    component: () => import('@/views/admin/insulin-pump/list/List.vue'),
  },

  {
    path: '/system/insulin-pump/edit/:id',
    name: 'system-insulin-pump-edit',
    component: () => import('@/views/admin/insulin-pump/edit/Edit.vue'),
  },

  {
    path: '/system/libre-reason/list',
    name: 'system-libre-reason-list',
    component: () => import('@/views/admin/libre-reason/list/List.vue'),
  },

  {
    path: '/system/libre-reason/edit/:id',
    name: 'system-libre-reason-edit',
    component: () => import('@/views/admin/libre-reason/edit/Edit.vue'),
  },

  {
    path: '/system/pharmacy/list',
    name: 'system-pharmacy-list',
    component: () => import('@/views/admin/pharmacy/list/List.vue'),
  },

  {
    path: '/system/pharmacy/edit/:id',
    name: 'system-pharmacy-edit',
    component: () => import('@/views/admin/pharmacy/edit/Edit.vue'),
  },
]
