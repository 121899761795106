/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addShift({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/shifts`, payload)
        .then(response => {
          // commit('ADD_SHIFT', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchShifts({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/shifts`)
        .then(response => {
          commit('SET_SHIFTS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchShiftLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/shift-labels`)
        .then(response => {
          commit('SET_SHIFT_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchShift(context, { shiftId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/shifts/${shiftId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateShift({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/shifts/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeShift({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/shifts/${pubId}`)
        .then(response => {
          commit('REMOVE_SHIFT', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
