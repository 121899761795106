export default {
  users: [],
  userLabels: [],
  pharmacists: [],
  pharmacistLabels: [],
  cdeHcps: [],
  cdeHcpLabels: [],
  patients: [],
  patientLabels: [],
  admins: [],
  adminLabels: [],
}
