import Vue from 'vue'

// axios
import axios from 'axios'

const token = localStorage.getItem('accessToken')

const axiosIns = axios.create({
  // // add your headers here
  // timeout: 10000,
  // baseURL: 'https://some-domain.com/api/',
  // // You can add your headers here
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

// Add request/response interceptor
axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
