/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addClockTitle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/clock_title`, payload)
        .then(response => {
          // commit('ADD_CLOCK_TITLE', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchClockTitles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/clock_title`)
        .then(response => {
          commit('SET_CLOCK_TITLES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchClockTitleLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/auth/clock_title_labels`)
        .then(response => {
          commit('SET_CLOCK_TITLE_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchClockTitle(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/clock_title/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateClockTitle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/clock_title/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeClockTitle({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/clock_title/${pubId}`)
        .then(response => {
          commit('REMOVE_CLOCK_TITLE', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchClockTitleLabelsByUser({ commit }, { userPubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/auth/clock_title_by_user/${userPubId}`)
        .then(response => {
          commit('SET_CLOCK_TITLE_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchActiveClockTitles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/profile/active_clock_titles`)
        .then(response => {
          commit('SET_ACTIVE_CLOCK_TITLES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
