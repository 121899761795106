export default {
  ADD_INSULIN_PUMP(state, item) {
    state.insulinPumps.unshift(item)
  },
  SET_INSULIN_PUMPS(state, insulinPumps) {
    state.insulinPumps = insulinPumps
  },
  UPDATE_INSULIN_PUMP(state, category) {
    const productIndex = state.insulinPumps.findIndex(p => p.id === category.id)
    Object.assign(state.insulinPumps[productIndex], category)
  },
  REMOVE_INSULIN_PUMP(state, itemId) {
    const ItemIndex = state.insulinPumps.findIndex(p => p.id === itemId)
    state.insulinPumps.splice(ItemIndex, 1)
  },
  SET_INSULIN_PUMP_LABELS(state, insulinPumps) {
    state.insulinPumpLabels = insulinPumps
  },
}
