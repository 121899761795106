import state from './StoreState'
import mutations from './StoreMutations'
import actions from './StoreActions'
import getters from './StoreGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
