export default {
  ADD_CLOCK_TITLE(state, item) {
    state.clockTitles.unshift(item)
  },
  SET_CLOCK_TITLES(state, clockTitles) {
    state.clockTitles = clockTitles
  },
  UPDATE_CLOCK_TITLE(state, category) {
    const productIndex = state.clockTitles.findIndex(p => p.id === category.id)
    Object.assign(state.clockTitles[productIndex], category)
  },
  REMOVE_CLOCK_TITLE(state, itemId) {
    const ItemIndex = state.clockTitles.findIndex(p => p.id === itemId)
    state.clockTitles.splice(ItemIndex, 1)
  },
  SET_CLOCK_TITLE_LABELS(state, clockTitles) {
    state.clockTitleLabels = clockTitles
  },
  SET_ACTIVE_CLOCK_TITLES(state, clockTitles) {
    state.activeClockTitles = clockTitles
  },
}
