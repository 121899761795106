export default {
  ADD_DIABETES_TYPE(state, item) {
    state.diabetesTypes.unshift(item)
  },
  SET_DIABETES_TYPES(state, diabetesTypes) {
    state.diabetesTypes = diabetesTypes
  },
  UPDATE_DIABETES_TYPE(state, category) {
    const productIndex = state.diabetesTypes.findIndex(p => p.id === category.id)
    Object.assign(state.diabetesTypes[productIndex], category)
  },
  REMOVE_DIABETES_TYPE(state, itemId) {
    const ItemIndex = state.diabetesTypes.findIndex(p => p.id === itemId)
    state.diabetesTypes.splice(ItemIndex, 1)
  },
  SET_DIABETES_TYPE_LABELS(state, diabetesTypes) {
    state.diabetesTypeLabels = diabetesTypes
  },
}
