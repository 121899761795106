// eslint-disable-next-line import/extensions
import state from './rolesStoreState.js'
import mutations from './rolesStoreMutations'
import actions from './rolesStoreActions'
import getters from './rolesStoreGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
