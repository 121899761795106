export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
  },

  {
    path: '/apps/job-titles/list',
    name: 'apps-job-titles-list',
    component: () => import('@/views/apps/jobtitles/job-title-list/JobtitleList.vue'),
  },
  {
    path: '/apps/job-titles/edit/:id',
    name: 'apps-job-title-edit',
    component: () => import('@/views/apps/jobtitles/job-title-edit/JobTitleEdit.vue'),
  },
]
