export default {
  ADD_DIABETES_COMPLICATION(state, item) {
    state.diabetesComplications.unshift(item)
  },
  SET_DIABETES_COMPLICATIONS(state, diabetesComplications) {
    state.diabetesComplications = diabetesComplications
  },
  UPDATE_DIABETES_COMPLICATION(state, category) {
    const productIndex = state.diabetesComplications.findIndex(p => p.id === category.id)
    Object.assign(state.diabetesComplications[productIndex], category)
  },
  REMOVE_DIABETES_COMPLICATION(state, itemId) {
    const ItemIndex = state.diabetesComplications.findIndex(p => p.id === itemId)
    state.diabetesComplications.splice(ItemIndex, 1)
  },
  SET_DIABETES_COMPLICATION_LABELS(state, diabetesComplications) {
    state.diabetesComplicationLabels = diabetesComplications
  },
}
