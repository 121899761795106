/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addDiabetesMedicineBrand({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/diabetes-medicine-brand`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchDiabetesMedicineBrands({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/diabetes-medicine-brand`)
        .then(response => {
          commit('SET_DIABETES_MEDICINE_BRANDS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchDiabetesMedicineBrandLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/diabetes_medicine_brand_labels/`)
        .then(response => {
          commit('SET_DIABETES_MEDICINE_BRAND_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchDiabetesMedicineBrand(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/diabetes-medicine-brand/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateDiabetesMedicineBrand({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/diabetes-medicine-brand/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeDiabetesMedicineBrand({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/diabetes-medicine-brand/${pubId}`)
        .then(response => {
          commit('REMOVE_DIABETES_MEDICINE_BRAND', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
