/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addInsulin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/insulin`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulins({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/insulin`)
        .then(response => {
          commit('SET_INSULINS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulinLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/insulin_labels/`)
        .then(response => {
          commit('SET_INSULIN_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchInsulin(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/insulin/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateInsulin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/insulin/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeInsulin({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/insulin/${pubId}`)
        .then(response => {
          commit('REMOVE_INSULIN', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
