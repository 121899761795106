export default [
  {
    path: '/dashboard-cde',
    name: 'dashboard-cde',
    component: () => import('@/views/Home-Pharmacy.vue'),
    meta: {
      resource: 'CDE',
      action: 'read',
    },
  },
  {
    path: '/cde/patient/list',
    name: 'cde-patients-list',
    component: () => import('@/views/apps/user/cde-patient-list/UsersList.vue'),
    meta: {
      resource: 'CDE',
      action: 'read',
    },
  },
  // Add new visit data
  {
    path: '/cde/patient-new-visit/:id',
    name: 'cde-patient-add-visit-data',
    component: () => import('@/views/apps/user/patient-data-view/Patient-MainView.vue'),
    meta: {
      resource: 'CDE',
      action: 'read',
    },
  },

  {
    path: '/cde/patient-visit/:id',
    name: 'cde-patient-visit-data',
    component: () => import('@/views/apps/user/patient-list/Main-View.vue'),
    meta: {
      resource: 'CDE',
      action: 'read',
    },
  },
]
