/* eslint-disable no-undef,no-unused-vars */
import axios from '@axios'
import apiConfig from '@/api/apiConfig'

export default {

  addCareTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${apiConfig.apiUrl}/admin/care-team`, payload)
        .then(response => {
          // commit('ADD_SHIFT', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCareTeams({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/care-team`)
        .then(response => {
          commit('SET_CARE_TEAMS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCareTeamLabels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/resources/care_team_labels/`)
        .then(response => {
          commit('SET_CARE_TEAM_LABELS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchCareTeam(context, { pubId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${apiConfig.apiUrl}/admin/care-team/${pubId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateCareTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${apiConfig.apiUrl}/admin/care-team/${payload.id}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeCareTeam({ commit }, pubId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${apiConfig.apiUrl}/admin/care-team/${pubId}`)
        .then(response => {
          commit('REMOVE_CARE_TEAM', pubId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
