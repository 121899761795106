export default {
  ADD_DIABETES_MEDICINE_BRAND(state, item) {
    state.diabetesMedicineBrands.unshift(item)
  },
  SET_DIABETES_MEDICINE_BRANDS(state, diabetesMedicineBrands) {
    state.diabetesMedicineBrands = diabetesMedicineBrands
  },
  UPDATE_DIABETES_MEDICINE_BRAND(state, category) {
    const productIndex = state.diabetesMedicineBrands.findIndex(p => p.id === category.id)
    Object.assign(state.diabetesMedicineBrands[productIndex], category)
  },
  REMOVE_DIABETES_MEDICINE_BRAND(state, itemId) {
    const ItemIndex = state.diabetesMedicineBrands.findIndex(p => p.id === itemId)
    state.diabetesMedicineBrands.splice(ItemIndex, 1)
  },
  SET_DIABETES_MEDICINE_BRAND_LABELS(state, diabetesMedicineBrands) {
    state.diabetesMedicineBrandLabels = diabetesMedicineBrands
  },
}
