export default {
  ADD_SHIFT(state, item) {
    state.shifts.unshift(item)
  },
  SET_SHIFTS(state, shifts) {
    state.shifts = shifts
  },
  UPDATE_SHIFT(state, category) {
    const productIndex = state.shifts.findIndex(p => p.id === category.id)
    Object.assign(state.shifts[productIndex], category)
  },
  REMOVE_SHIFT(state, itemId) {
    const ItemIndex = state.shifts.findIndex(p => p.id === itemId)
    state.shifts.splice(ItemIndex, 1)
  },
  SET_SHIFT_LABELS(state, shifts) {
    state.shiftLabels = shifts
  },
}
