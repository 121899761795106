export default {
  ADD_HOSPITALISATION_LIST(state, item) {
    state.hospitalisationLists.unshift(item)
  },
  SET_HOSPITALISATION_LISTS(state, hospitalisationLists) {
    state.hospitalisationLists = hospitalisationLists
  },
  UPDATE_HOSPITALISATION_LIST(state, category) {
    const productIndex = state.hospitalisationLists.findIndex(p => p.id === category.id)
    Object.assign(state.hospitalisationLists[productIndex], category)
  },
  REMOVE_HOSPITALISATION_LIST(state, itemId) {
    const ItemIndex = state.hospitalisationLists.findIndex(p => p.id === itemId)
    state.hospitalisationLists.splice(ItemIndex, 1)
  },
  SET_HOSPITALISATION_LIST_LABELS(state, hospitalisationLists) {
    state.hospitalisationListLabels = hospitalisationLists
  },
}
