/*
    Defines the API route we are using.
*/
const apiBackendUrl = `${process.env.VUE_APP_API_URL}`
const pdfGenerateUrl = `${process.env.VUE_APP_PDF_GENERATE_URL}`
export default {
  apiUrl: apiBackendUrl,
  pdfGenerateURL: pdfGenerateUrl,
  isApiProduction: true,
  // Endpoints
  loginEndpoint: `${apiBackendUrl}/auth/login`,
  registerEndpoint: `${apiBackendUrl}/auth/register`,
  refreshEndpoint: `${apiBackendUrl}/auth/refresh-token`,
  logoutEndpoint: `${apiBackendUrl}/profile/auth/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
