export default {
  ADD_GLUCOSE_MONITOR(state, item) {
    state.glucoseMonitors.unshift(item)
  },
  SET_GLUCOSE_MONITORS(state, glucoseMonitors) {
    state.glucoseMonitors = glucoseMonitors
  },
  UPDATE_GLUCOSE_MONITOR(state, category) {
    const productIndex = state.glucoseMonitors.findIndex(p => p.id === category.id)
    Object.assign(state.glucoseMonitors[productIndex], category)
  },
  REMOVE_GLUCOSE_MONITOR(state, itemId) {
    const ItemIndex = state.glucoseMonitors.findIndex(p => p.id === itemId)
    state.glucoseMonitors.splice(ItemIndex, 1)
  },
  SET_GLUCOSE_MONITOR_LABELS(state, glucoseMonitors) {
    state.glucoseMonitorLabels = glucoseMonitors
  },
}
